<template>
  <v-container>
    <!-- BOC:[error] -->
    <ApiErrorDialog v-if="api.isError" :api="api" />
    <ApiErrorDialog v-if="QuestionApi.isError" :api="QuestionApi" />
    <!-- EOC -->

    <v-row class="tab-bar-card ma-0">
      <v-col
        v-for="(nav, j) in navs"
        :key="j"
        class="pa-0"
        :class="nav.id == selected.id ? 'selected-tab-card' : 'tab-card'"
        @click="
          () => {
            selected = nav;
          }
        "
      >
        {{ nav.name }}
      </v-col>
    </v-row>
    <div class="content-card">
      <div v-if="selected.text == 'General'" v-html="rules" />
      <div v-else-if="selected.text == 'Topics'">
        <div class="px-3 text-center mt-1">
          {{ $_parseSchoolYear(schoolYear) }}
        </div>
        <div v-for="(topic, i) in topics" :key="i" class="topic">
          <div class="text-caption" v-if="topic && topic.chapter_name">
            #{{ i + 1 }} | {{ topic.chapter_name }}
          </div>
          <div class="d-flex" v-if="topic && topic.name">
            <span class="text-h6">{{ topic.name }}</span>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              small
              color="brown lighten-3"
              class="brown--text"
              @click="expand == i ? (expand = null) : (expand = i)"
            >
              <v-icon dark v-if="expand == i"> mdi-chevron-up </v-icon>
              <v-icon dark v-else> mdi-chevron-down </v-icon>
              {{ $t("string.sample_question") }}
            </v-btn>
          </div>
          <div v-if="expand == i" v-html="topic.question_code.question" />
        </div>
      </div>
      <div v-else-if="selected.text == 'Sponsors'" class="">
        <div v-if="sponsors && sponsors.Series" class="pb-3">
          <div
            v-for="(sponsor, j) in sponsors.Series"
            :key="j"
            class="d-flex flex-column align-center mx-3 topic"
          >
            <v-img
              v-if="sponsor.Sponsor.logoUrl"
              :src="sponsor.Sponsor.logoUrl"
              max-width="100"
            >
            </v-img>
            <a
              v-if="sponsor.Sponsor.websiteUrl"
              href="sponsor.Sponsor.websiteUrl"
              target="_blank"
              class="text-h6 blue--text text--darken-2"
              >{{ sponsor.Sponsor.name }}</a
            >
            <div v-else>{{ sponsor.Sponsor.name }}</div>
          </div>
        </div>

        <Sponsors v-if="sponsors && sponsors.Event" :items="sponsors.Event"/>
      </div>
    </div>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
    Sponsors: () =>
      import(
        /* webpackChunkName: "component-sponsors" */ "@/components/Sponsor/Sponsors.vue"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    series: (state) => state.series.data,
  }),
  props: [
    //
  ],
  data: () => ({
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    QuestionApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    schoolYear: null,
    expand: null,
    rules: "",
    questions: [],
    chapters: [],
    topics: [],
    sponsors: [],
    navs: [],
    selected: {},
  }),
  created() {
    this.navs = [
      {
        id: 1,
        text: "General",
        name: this.$t("string.rules"),
      },
      {
        id: 2,
        text: "Topics",
        name: this.$t("string.topics"),
      },
      {
        id: 3,
        text: "Sponsors",
        name: this.$t("string.sponsors"),
      },
    ];
    this.selected = this.navs[0];

    this.api.url =
      this.$api.servers.event +
      "/api/v2/" +
      this.$_getLocale() +
      "/game/event/info";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      if (resp.Rule) this.rules = resp.Rule.text;
      if (resp.Topic && this.series.type != "book")
        this.questions = resp.Topic.SchoolYearTopic;
      if (resp.Sponsor) this.sponsors = resp.Sponsor;

      if (this.series.type == "book") {
        this.navs = this.navs.filter((nav) => nav.text !== "Topics");
      }

      this.chapters = [
        ...new Map(
          this.questions.map((item) => [item["chapterKey"], item])
        ).values(),
      ];

      this.getQuestions();
    };
  },
  mounted() {
    if (this.auth.Classroom) {
      this.schoolYear = this.auth.Classroom.schoolYear;
    } else {
      this.schoolYear = this.auth.User.schoolYear;
    }

    this.api.params = {
      eventCode: this.$route.params.code,
      schoolYear: this.schoolYear,
      institutionId: this.auth.Institution ? this.auth.Institution.id : null,
    };
    this.$api.fetch(this.api);
  },
  methods: {
    async getQuestions() {
      for (let i = 0; i < this.chapters.length; i++) {
        await this.questionApiCall(this.chapters[i].chapterKey);
      }
    },
    async questionApiCall(chapterKey) {
      this.QuestionApi.url =
        this.$api.servers.question +
        "/v2/chapters/" +
        chapterKey +
        "/questions?lang=" +
        this.$_getLocale();

      this.QuestionApi.callbackReset = () => {
        this.QuestionApi.isLoading = true;
        this.QuestionApi.isError = false;
      };

      this.QuestionApi.callbackError = (e) => {
        this.QuestionApi.isLoading = false;
        this.QuestionApi.isError = true;
        this.QuestionApi.error = e;
      };
      this.QuestionApi.callbackSuccess = (resp) => {
        this.questions.forEach((q) => {
          let topic = resp.find((element) => element.key == q.questionKey);
          if (topic) {
            this.topics.push(topic);
          }
        });
        this.QuestionApi.isLoading = false;
      };
      this.QuestionApi.method = "GET";

      // this.QuestionApi.params = { lang: this.$_getLocale() };
      await this.$api.fetch(this.QuestionApi);
    },
  },
};
</script>
    <style scoped>
.tab-bar-card {
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #e1c8aa;
  text-align: center;
  display: flex;
}

.tab-card {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 5px 8px 5px;
  width: 100%;
  box-shadow: #706454 0 3px 1px;
  background-color: #cbae8c;
  text-align: center;
  color: #fff;
  text-shadow: #876a47 3px 0px 0px, #876a47 2.83487px 0.981584px 0px,
    #876a47 2.35766px 1.85511px 0px, #876a47 1.62091px 2.52441px 0px,
    #876a47 0.705713px 2.91581px 0px, #876a47 -0.287171px 2.98622px 0px,
    #876a47 -1.24844px 2.72789px 0px, #876a47 -2.07227px 2.16926px 0px,
    #876a47 -2.66798px 1.37182px 0px, #876a47 -2.96998px 0.42336px 0px,
    #876a47 -2.94502px -0.571704px 0px, #876a47 -2.59586px -1.50383px 0px,
    #876a47 -1.96093px -2.27041px 0px, #876a47 -1.11013px -2.78704px 0px,
    #876a47 -0.137119px -2.99686px 0px, #876a47 0.850987px -2.87677px 0px,
    #876a47 1.74541px -2.43999px 0px, #876a47 2.44769px -1.73459px 0px,
    #876a47 2.88051px -0.838247px 0px;
}

.selected-tab-card {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 5px 5px 5px;
  width: 100%;
  border: #68fffc 4px solid;
  background-color: #d3fdfc;
  text-align: center;
  color: #fff;
  text-shadow: #29a399 3px 0px 0px, #29a399 2.83487px 0.981584px 0px,
    #29a399 2.35766px 1.85511px 0px, #29a399 1.62091px 2.52441px 0px,
    #29a399 0.705713px 2.91581px 0px, #29a399 -0.287171px 2.98622px 0px,
    #29a399 -1.24844px 2.72789px 0px, #29a399 -2.07227px 2.16926px 0px,
    #29a399 -2.66798px 1.37182px 0px, #29a399 -2.96998px 0.42336px 0px,
    #29a399 -2.94502px -0.571704px 0px, #29a399 -2.59586px -1.50383px 0px,
    #29a399 -1.96093px -2.27041px 0px, #29a399 -1.11013px -2.78704px 0px,
    #29a399 -0.137119px -2.99686px 0px, #29a399 0.850987px -2.87677px 0px,
    #29a399 1.74541px -2.43999px 0px, #29a399 2.44769px -1.73459px 0px,
    #29a399 2.88051px -0.838247px 0px;
}

.content-card {
  text-align: left !important;
  margin-top: 5px;
  padding: 5px 10px;
  color: #785c3a;
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #fff9f1;
  text-align: center;
}

.topic {
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
  background-color: #e0d4c4;
}
</style>